import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'

import {
  handleOnClickVerCoberturas,
  handleOnClickContratar,
  scrollToFormIfFormUrlParam,
} from './../common'
import Form from './Form'


class CalcularPrecio extends PureComponent {
  handleOnClickVerCoberturas = handleOnClickVerCoberturas

  handleOnClickContratar = handleOnClickContratar

  componentDidMount () {
    scrollToFormIfFormUrlParam()
  }

  render () {
    return (
      <div>
        <div className="container pt-5 pb-5">

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div
                id="card-modulo"
                className="card mb-4"
              >
                <div className="card-body">
                  <h1 className="h3">
                    Seguro para módulos
                  </h1>

                  <div className="mt-5">
                    <Tabs defaultActiveKey="info" >
                      <Tab eventKey="info" title="Información general">
                        <div className="ml-2 mr-2 mt-3 mb-3">
                          <p>
                            Con el Seguro para módulos no tendrá que preocuparte por los gastos ocasionados por la pérdida o daños sufridos.
                            <br /><br />
                            Podrá configurar qué coberturas serán las que protejan su vehículo y cómo pagar la cuota.
                          </p>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="coberturas"
                        title="Coberturas"
                        id="coberturas-tab-link"
                      >
                        <div className="ml-2 mr-2 mt-3 mb-3">
                          <div className="row">
                            <div className="col">
                              <h6>- Continente</h6>
                              <ul className="small">
                                <li>El módulo</li>
                                <li>Anexos, vallas y zonas exteriores</li>
                                <li>Arbolado, plantas y jardines</li>
                              </ul>

                              <h6>- Contenido</h6>
                              <p className="small ml-2">
                                Ajuar y objectos personales que se encuentran en el interior de la modulo y sean propiedad del asegurado
                              </p>

                              <h6>- Incendio y complementarios</h6>
                              <ul className="small">
                                <li>Incendio</li>
                                <li>Extinción</li>
                                <li>Explosión</li>
                                <li>Rayo</li>
                                <li>Efectos secundarios</li>
                              </ul>

                              <h6>- Daños eléctricos</h6>

                              <h6>- Fenómenos atmosféricos y otros daños materiales</h6>
                              <ul className="small">
                                <li>Actos de vandalismo o malintencionados</li>
                                <li>Lluvia, viento (excepto tornados), pedrisco, nieve</li>
                                <li>Inundación por rotura, desboardamiento o desviación accidental</li>
                                <li>Humo producido por fugas o escapes repentinos</li>
                                <li>Choque o impacto de vehículos terrestres</li>
                                <li>Caída de astronaves, aeronaves u objetos que caigan de los bienes asegurados</li>
                                <li>Ondas sónicas producidas por astronaves o aeronaves</li>
                              </ul>

                              <h6>- Daños por agua</h6>
                              <ul className="small">
                                <li>Escapes de agua utilizada en la modulo</li>
                                <li>Olvidos u omisiones en el cierre de grifos</li>
                                <li>Gastos de localización y reparación</li>
                                <li>Responsabilidad civil</li>
                              </ul>
                            </div>
                            <div className="col">
                              <h6>- Gastos</h6>
                              <ul className="small">
                                <li>Extición y/o aminoración</li>
                                <li>Salvamento</li>
                                <li>Demolición y/o desescombro</li>
                                <li>Desalojo forzoso</li>
                                <li>Restitución estética</li>
                              </ul>

                              <h6>- Robo</h6>
                              <ul className="small">
                                <li>Desperfectos en continente (1000€)</li>
                                <li>Robo ajuar</li>
                              </ul>

                              <h6>- Responsabilidad Civil Familiar</h6>

                              <h6>- Roturas de Espejos y Cristales</h6>

                              <h6>- Rotura de sanitarios</h6>

                              <h6>- Rotura de encimeras</h6>

                              <h6>- Rotura de placas vitrocerámicas</h6>

                              <h6>- Asistencia Hogar</h6>

                              <h6>- Reclamación y defensa jurídica</h6>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    <hr />
                    <div className="d-md-flex justify-content-between">
                      <div>
                        <div
                          className="btn btn-primary mr-md-2 mb-1 d-block d-md-inline-block"
                          onClick={this.handleOnClickContratar}
                        >
                          CONTRATAR
                        </div>
                        {/* <div
                          className="btn btn-outline-primary mr-md-2 mb-1 d-block d-md-inline-block">
                            SOLICITAR LLAMADA
                        </div> */}
                      </div>
                      <div
                        className="btn btn-warning mb-1 mt-3 mt-md-0 d-block d-md-inline-block"
                        onClick={this.handleOnClickVerCoberturas}
                      >Ver todas las coberturas {'>'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="form-content">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="card card-calcular-precio">
                <div className="card-header">
                  Formulario presupuesto / información
                </div>
                <div className="card-body">
                  <Form />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CalcularPrecio
