import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faUserTie, faThumbsUp, faUser, faBookmark } from '@fortawesome/free-solid-svg-icons'


export default class Home extends Component {
  handleOnClickCalcularPrecio = () => {
    const products = document.getElementById('products')
    window.scrollTo(0, products.offsetTop)
  }

  render () {
    return (
      <>
        <div className="jumbo">
          <div className="overlay"></div>
          <video playsinline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" id="bck-video">
            <source src="/resources/video/van.mp4" type="video/mp4" />
          </video>
          <div className="container h-100">
            <div className="d-flex h-100 text-center align-items-center">
              <div className="w-100 text-white">
                <h1 className="display-3">Expertos en camping</h1>

                <p className="h3">Contrata tu seguro online</p>

                <br />

                <div className="d-flex justify-content-center">
                  <div
                    className="btn btn-primary btn-lg"
                    onClick={this.handleOnClickCalcularPrecio}
                  >
                    CALCULAR PRECIO
                  </div>
                  {/* <div className="btn btn-outline-primary btn-lg ml-4">TE LLAMAMOS</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="container pt-5" id="products">
          <div className="row">
            <div className="col">
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4">
                <div className="col mb-4">
                  <div
                    className="card h-100"
                    style={{
                      backgroundImage: 'url(./resources/img/caravana-estatica-white.png)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title montserrat">
                        <img src="/resources/img/icons/caravan-static.svg" height="80px" className="h3 ml-1 mb-1 float-right" />
                        Caravanas estaticas
                      </h5>
                      <p className="card-text">
                        Asegura tu segunda casa.
                        <br />
                        <br />
                        Desde <strong>45€</strong>
                      </p>
                    </div>
                    <div className="card-footer p-0 border-0 d-flex">
                      <div className="btn-group btn-footer">
                        <Link
                          className="btn btn-info"
                          to="/seguros/caravana/estatica"
                        >
                          <img src="/resources/img/icons/info.svg" height="20px" className="m-1" />
                        </Link>
                        <Link
                          className="btn btn-primary"
                          to="/seguros/caravana/estatica?form"
                        >
                          CALCULAR PRECIO
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col mb-4">
                  <div
                    className="card h-100"
                    style={{
                      backgroundImage: 'url(./resources/img/caravana-circulacion.png)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title montserrat">
                        <img src="/resources/img/icons/caravan-touring.svg" height="80px" className="h3 ml-1 mb-1 float-right" />
                        Caravanas circulación
                      </h5>
                      <p className="card-text">
                        En movimiento, pero siempre cubierto.
                        <br />
                        <br />
                        Desde <strong>60€</strong>
                      </p>
                    </div>
                    <div className="card-footer p-0 border-0 d-flex">
                      <div className="btn-group btn-footer">
                        <Link
                          className="btn btn-info"
                          to="/seguros/caravana/circulacion"
                        >
                          <img src="/resources/img/icons/info.svg" height="20px" className="m-1" />
                        </Link>
                        <Link
                          className="btn btn-primary"
                          to="/seguros/caravana/circulacion?form"
                        >
                          CALCULAR PRECIO
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col mb-4">
                  <div
                    className="card h-100"
                    style={{
                      backgroundImage: 'url(./resources/img/mobile-home.png)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title montserrat">
                        <img src="/resources/img/icons/modulo3.svg" height="80px" className="ml-1 mb-1 float-right" />
                        Módulos
                      </h5>
                      <p className="card-text">
                        Las mismas necesidades que una casa.
                        <br />
                        <br />
                        Desde <strong>65€</strong>
                      </p>
                    </div>
                    <div className="card-footer p-0 border-0 d-flex">
                      <div className="btn-group btn-footer">
                        <Link
                          className="btn btn-info"
                          to="/seguros/modulo"
                        >
                          <img src="/resources/img/icons/info.svg" height="20px" className="m-1" />
                        </Link>
                        <Link
                          className="btn btn-primary"
                          to="/seguros/modulo?form"
                        >
                          CALCULAR PRECIO
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col mb-4">
                  <div
                    className="card h-100"
                    style={{
                      backgroundImage: 'url(./resources/img/casita-madera.png)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                  >
                    <div className="card-body">
                      <h5 className="card-title montserrat">
                        <img src="/resources/img/icons/wooden-house.svg" height="80px" className="ml-1 mb-1 float-right" />
                        Casitas de madera
                      </h5>
                      <p className="card-text">
                        Tu casa, asegurada.
                        <br />
                        <br />
                        Desde <strong>65€</strong>
                      </p>
                    </div>
                    <div className="card-footer p-0 border-0 d-flex">
                      <div className="btn-group btn-footer">
                        <Link
                          className="btn btn-info"
                          to="/seguros/casas-de-madera"
                        >
                          <img src="/resources/img/icons/info.svg" height="20px" className="m-1" />
                        </Link>
                        <Link
                          className="btn btn-primary"
                          to="/seguros/casas-de-madera?form"
                        >
                          CALCULAR PRECIO
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col mb-4">
                  <div className="card h-100">
                    <div className="card-body">
                      <h5 className="card-title montserrat">
                        Otros
                      </h5>
                      <div className="d-flex justify-content-center flex-wrap">
                        <Link
                          to="/seguros/casas-de-madera"
                        >
                          <img src="/resources/img/icons/wooden-house.svg" height="60px" className="m-1" />
                        </Link>
                        {/* <img src="/resources/img/icons/modulo.svg" height="60px" className="m-1" />
                        <img src="/resources/img/icons/modulo2.svg" height="60px" className="m-1" />
                        <img src="/resources/img/icons/car.svg" height="60px" className="m-1" />
                        <img src="/resources/img/icons/bicycle.svg" height="60px" className="m-1" />
                        <img src="/resources/img/icons/briefcase.svg" height="60px" className="m-1" />
                      </div>
                    </div>
                    {/* <div className="card-footer p-0 border-0 d-flex">
                      <div className="btn btn-primary btn-footer">
                        VER TODOS
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container p-5">
          <hr />
        </div>


        {/* <div>
          <div className="container">

            <div className="row">
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="montserrat text-center color2">
                    Adaptado <br /> a ti
                  </h2>
                  <p>
                    Tienes la opción de poder elegir en todo momento como quieres asegurar tu embarcación.
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="montserrat  text-center color2">
                    Asistencia <br /> marítima
                  </h2>
                  <p>
                    ¿Tienes algún problema con tu embarcación? No te preocupes, contrata el servicio de asistencia y remolque marítimo. Te asegurarás la tranquilidad ante los imprevistos.
                  </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-4 mb-3">
                  <h2 className="montserrat text-center color2">
                    Posibilidad de ampliar <br /> las coberturas
                  </h2>
                  <p>
                    Opción de ampliar las coberturas de responsabilidad civil o accidentes de los ocupantes, entre otras, para así viajar con aún más tranquilidad.
                    <br className="d-none d-md-block" />
                    <br className="d-none d-md-block" />
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="container p-5">
          <hr />
        </div> */}

        <div className="container">

          <h1 className="montserrat text-center pb-4">
            RAZONES PARA CONFIAR EN NOSOTROS
          </h1>

          <div className="card">
            <div className="card-body">
              <div className="row montserrat">
                <div className="col">
                  <div className="text-center h5">
                    <FontAwesomeIcon icon={faUserTie} className="h2 m-3" />
                    <div>
                      Especialistas
                      <br /><br />
                    </div>
                    <div className="small mt-3">
                      Llevamos más de 15 años en el mercado
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center h5">
                    <FontAwesomeIcon icon={faThumbsUp} className="h2 m-3" />
                    <div>
                      +8000 caravanas y similares
                    </div>
                    <div className="small mt-3">
                      Y cada día seguimos creciendo
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center h5">
                    <FontAwesomeIcon icon={faBookmark} className="h2 m-3" />
                    <div>
                      Seguros flexibles y personalizados
                    </div>
                    <div className="small mt-3">
                      Nos adaptamos a tus necesidades
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center h5">
                    <FontAwesomeIcon icon={faUser} className="h2 m-3" />
                    <div>
                      Equipos personalizados
                    </div>
                    <div className="small mt-3">
                      Varios equipos con diferentes especializaciones
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="text-center h5">
                    <FontAwesomeIcon icon={faPhone} className="h2 m-3" />
                    <div>
                      24/7
                      <br /><br />
                    </div>
                    <div className="small mt-3">
                      Disponibles cada día de la semana a cualquier hora
                    </div>
                  </div>
                </div>
              </div>
              <hr className="m-4 mt-5" />
              <div className="row">
                <div className="col-md-3 text-center mb-2 mb-md-0">
                  <img src="/resources/img/logo-campings-de-espana.jpg" />
                </div>
                <div className="col-md-9">
                  <h2>
                    Acuerdo de colaboración con la FEEC
                  </h2>
                  <h4>
                    Proveedor oficial de seguros para campings.
                  </h4>
                </div>
              </div>
              <hr className="m-4" />
              <div className="row">
                <div className="col-md-3 text-center mb-3 mb-md-0">
                  <img
                    src="/resources/img/logo-campingred.png"
                    width="200px"
                  />
                </div>
                <div className="col-md-9">
                  <h2>
                    Acuerdo de colaboración con CampingRed
                  </h2>
                  <h4>
                    Proveedor de seguros para campings y clientes particulares.
                  </h4>
                </div>
              </div>
              {/* <hr className="m-4 mt-5" />
              <div>
                <h4>
                  <a href="#">
                    Saber más sobre nosotros {'>'}
                  </a>
                </h4>
              </div> */}
            </div>
          </div>

        </div>

        <div className="container p-5">
          <hr />
        </div>

        <div className="container">

          <div className="row">
            <div className="col-md">
              <div className="card mb-4">
                <div className="card-body">
                  <img
                    src="/resources/img/icons/family.svg"
                    className="rounded-circle float-right m-1 ml-3 border"
                    width="100px"
                  />
                  <p>
                    “
                    Sufrimos daños importantes a consecuencia del temporal Gloria.
                    <br />
                    La agencia nos ayudó en todo momento a gestionar los siniestros y nos orientaron en cada momento.
                    <br />
                    ¡Gracias por vuestra atención y ayuda!
                    ”
                  </p>
                </div>
                <div className="card-footer">
                  <div className="montserrat">
                    Andrés, María y Jesús
                  </div>
                  <div className="text-muted">
                    Bilbao
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md">
              <div className="card mb-4">
                <div className="card-body">
                  <img
                    src="/resources/img/icons/grandpas.svg"
                    className="rounded-circle float-right m-1 ml-3 border"
                    width="100px"
                  />
                  <p>
                    “
                    Llevamos varios años con ellos. Destaca su profesionalidad y atención en cualquier duda, siniestro o gestión.
                    ”
                  </p>
                </div>
                <div className="card-footer">
                  <div className="montserrat">
                    Miriam y Joan
                  </div>
                  <div className="text-muted text-small">
                    Valencia
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </>
    )
  }
}
