import React, { Component } from 'react';

class PoliticaCookies extends Component {
  render () {
    return (
      <div className="bg-light">
        <div className="container p-5">

          <div className="post-title">
            <h1 className="h1">Política de cookies</h1>
          </div>

          <div className="post-content">

            <p>Nuestro sitio web utiliza una tecnología llamada «cookie», con el fin de poder obtener información sobre el uso
              del sitio web, mejorar la experiencia del usuario y garantizar el su correcto funcionamiento.</p>

            <h5>Definición de Cookie</h5>
            <p>Una «cookie» es un pequeño fragmento de texto que los sitios web envían al navegador y que se almacenan en el
              terminal del usuario, (ordenador o dispositivo móvil). Estos archivos tienen la finalidad de almacenar datos que
              podrán ser actualizados y recuperadas por la entidad responsable de su instalación. Así, cada vez que el usuario
              vuelve a visitar el mismo sitio web, el explorador recupera el archivo y lo envía al servidor del sitio web. Las
              cookies tienen varias funciones entre las que se incluyen, la de recoger información sobre las preferencias del
              usuario y recordarla, y en general, facilitar el uso del sitio web al usuario haciendo que el sitio sea más útil
              al personalizar su contenido.</p>
            <p>Las cookies se pueden clasificar en base a diferentes parámetros:</p>
            <p><b>1.- Titularidad:: </b><u>cookies propias y cookies de terceros</u>: dependiendo de cuál sea la entidad que
              gestione el dominio desde donde se envían las cookies y se traten los datos que se obtengan.<br />
              <b>2.- Duración::</b> <u>cookies de sesión y cookies persistentes</u>: de acuerdo con el plazo de tiempo que
              permanecen<br />
              almacenadas en el terminal del usuario.<br />
              <b>3.- Finalidad:: </b><u>cookies técnicas, cookies de personalización, cookies de análisis, cookies
                publicitarias, cookies de<br />
                publicidad comportamental,</u> de acuerdo con la finalidad para la que se traten los datos obtenidos.</p>
            <ul>
              <li><u>Cookies técnicas:: </u>Son aquellas que permiten al usuario la navegación a través de la página web o
                aplicación y la utilización de las diferentes opciones o servicios que en ella existen. Por ejemplo, controlar
                el tráfico y la comunicación de datos, identificar la sesión o acceder a las partes web de acceso restringido.
              </li>
              <li><u>Cookies de personalización: </u>Son aquellas que permiten al usuario acceder al servicio con algunas
                características de carácter general predefinidas en su terminal o que el propio usuario defina. Por ejemplo, el
                idioma, el tipo de navegador a través del cual accede al servicio o el diseño de contenidos seleccionado.</li>
              <li><u>Cookies de análisis: </u>Son aquellas que o bien tratadas por nosotros o por terceros, nos permiten
                cuantificar el número de usuarios y así realizar el análisis estadístico de la utilización que hacen los
                usuarios del servicio ofrecido. Para ello se analiza su navegación en nuestra página web para mejorar la oferta
                de productos o servicios que le ofrecemos.</li>
              <li><u>Cookies publicitarias:: </u>Son aquellas que permiten la gestión, de la forma más eficaz posible, los
                espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde
                la que presta el servicio solicitado sobre la base de criterios como el contenido editado o la frecuencia en a
                que se muestran los anuncios.</li>
              <li><u>Cookies de publicidad comportamental: </u>Son aquellas que permiten la gestión, de la manera más eficaz
                posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o
                plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de
                los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite
                desarrollar un perfil específico para mostrar publicidad en función del mismo.</li>
            </ul>

            <p><strong>Cookies utilizadas en nuestro sitio web</strong></p>
            <p>Nuestro sitio web utiliza tanto cookies propias, que son las enviadas a su ordenador y gestionadas exclusivamente
              por nosotros para el mejor funcionamiento del sitio web, como cookies de terceros, con la función de servirnos
              como herramienta de análisis de la interacción los usuarios con nuestra web, gracias a ellas obtenemos una
              información que puede ayudar a mejorar la navegación y dar un mejor servicio a los usuarios y clientes.</p>
            <p>Nuestro sitio web utiliza tanto cookies temporales de sesión, como cookies persistentes. Las cookies de sesión
              almacenan datos únicamente mientras el usuario accede a la web y las cookies persistentes almacenan los datos en
              el terminal para que sean accedidas y utilizadas en más de una sesión.</p>
            <p>Concretamente las cookies utilizadas en nuestro sitio web son:</p>

            <p><strong>Cookies propias:</strong></p>
            <p>Las cookies propias que utilizamos en nuestro sitio web son de carácter técnico referentes a la programación y la
              seguridad de la navegación, fundamentales para el funcionamiento de la web, y también de personalización para
              recordar la elección del idioma de navegación.</p>

            <p><b>Cookies de terceros:</b></p>
            <p>&nbsp;</p>

            <table className="table">
              <tbody>
                <tr>
                  <th width="180">
                    <p align="center">Nombre</p>
                  </th>
                  <th width="150">
                    <p align="center">Titular</p>
                  </th>
                  <th width="250">
                    <p align="center">Finalidad</p>
                  </th>
                  <th width="180">
                    <p align="center">Expiración</p>
                  </th>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>_ga<p></p>
                    <p>_gid</p>
                    <p>_gat</p>
                  </td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                    <a
                      href="https://policies.google.com/technologies/cookies?hl=es" target="_blank"
                      rel="noopener noreferrer">Google LLC</a>
                    <p></p>
                    <p>(Google Analytics)</p>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}><b>Analíticas:</b><br />
                    · Generar un identificador de usuario único, que es el que se utiliza para hacer recuento de cuántas veces
                    visita el sitio un usuario, así como la fecha de la primera y la última vez que visitó la web.<br />
                    · Registrar la fecha y hora de acceso.<br />
                    · Comprobar la necesidad de mantener la sesión de un usuario abierta o crear una nueva.<br />
                    · Identificar la sesión del usuario, para recoger la ubicación geográfica aproximada del ordenador que
                    accede a la web con efectos estadísticos.</td>
                  <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>ga: 2 años<p></p>
                    <p>_gid: 24 horas</p>
                    <p>_gat: 1 minuto</p>
                  </td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center' }}>NID<br />
                    CONSENT</td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="https://policies.google.com/technologies/types?hl=es" target="_blank"
                      rel="noopener noreferrer">Google LLC</a></td>
                  <td>· Almacena preferencias del usuario.</td>
                  <td>NID: 6 meses<br />
                    CONSENT: 18 años</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center' }}>__cfduid</td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="https://chatra.io/privacy-policy/" target="_blank"
                      rel="noopener noreferrer">Roger Wilco LLC</a></td>
                  <td>· Cookie DNS que ayuda a conectar con los servidores de Chatra.</td>
                  <td>30 días</td>
                </tr>
                <tr>
                  <td style={{ textAlign: 'center' }}>AWSALB, AWSALBCORS</td>
                  <td style={{ textAlign: 'center' }}>
                    <a href="https://chatra.io/privacy-policy/" target="_blank"
                      rel="noopener noreferrer">Roger Wilco LLC</a></td>
                  <td>· Utilizada para poder asistir con el chat.</td>
                  <td>7 días</td>
                </tr>
              </tbody>
            </table>

            <br />
            <br />

            <p>Para obtener más información sobre las cookies de este tercero puede clicar sobre el enlace que hay en su nombre.
            </p>
            <p>Finalmente, también se utilizan cookies de complemento (plug-in) para intercambiar contenidos sociales</p>
            <p><strong>Consentimiento</strong></p>
            <p>El consentimiento para el uso de las cookies antes enunciadas, por los plazos señalados y en las condiciones
              contenidas en la presente Política de Cookies, se obtendrá en la forma especificada en el aviso inicial de
              información sobre las cookies que se puede visualizar en el momento en que carga nuestra web.</p>
            <p><strong>Retirada del consentimiento</strong></p>
            <p>El usuario podrá retirar en cualquier momento su consentimiento relacionado con esta Política sobre Cookies, para
              ello tendrá que eliminar las cookies almacenadas en su equipo utilizando los ajustes y configuraciones de su
              navegador de Internet.</p>
            <p><strong>Modificación de la configuración de las cookies</strong><br />
              El usuario puede restringir, bloquear o borrar las cookies de cualquier sitio web, utilizando su navegador. En
              cada navegador la operativa es diferente, por lo que puede consultar los siguientes enlaces la forma de hacerlo:
            </p>

            <ul>
              <li>
                <a href="http://windows.microsoft.com/es-xl/internet-explorer/delete-manage-cookies#ie=”ie-10″"
                  target="_blank" rel="noopener noreferrer">Internet Explorer:</a> Herramientas -&gt; Opciones de Internet -&gt;
                Privacidad -&gt; Configuración.
              </li>
              <li>
                <a href="http://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank"
                  rel="noopener noreferrer">FireFox:</a> Herramientas -&gt; Opciones -&gt; Privacidad -&gt; Historial -&gt;
                Configuración Personalizada.
              </li>
              <li>
                <a href="http://support.google.com/chrome/answer/95647?hl=”es”" target="_blank"
                  rel="noopener noreferrer">Chrome:</a> Configuración -&gt; Mostrar opciones avanzadas -&gt; Privacidad -&gt;
                Configuración de contenido.
              </li>
              <li>
                <a href="http://www.apple.com/es/privacy/use-of-cookies/" target="_blank"
                  rel="noopener noreferrer">Safari:</a> Preferencias -&gt; Seguridad.
              </li>
              <li>
                <a href="http://www.apple.com/es/privacy/use-of-cookies/" target="_blank" rel="noopener noreferrer">Opera:</a>
                Configuración -&gt; Privacidad y seguridad.
              </li>
            </ul>

            <p>Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las
              siguientes:</p>
            <ul>
              <li><a href="http://www.youronlinechoices.eu/">Your online choices</a></li>
              <li><a href="https://www.ghostery.com/">Ghostery</a></li>
            </ul>

            <p><strong>Actualización de la política de cookies</strong></p>
            <p>Este sitio web puede modificar esta Política sobre Cookies, por ello le recomendamos revisar esta política cada
              vez que acceda a nuestro sitio web para estar adecuadamente informado sobre el uso que hacemos de las cookies.</p>
            <p>Última actualización: 2 de mayo de 2020</p>
          </div>
        </div>
      </div>
    )
  }
}

export default PoliticaCookies
