import React, { Component } from 'react'

import Form from './Form'


export default class Contacto extends Component {
  render () {
    return (
      <Form />
    )
  }
}
