import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import range from 'lodash.range'
// import Req from './Required'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const resetForm = () => {
  window.document.getElementById('calcular-precio-form').reset()
}

const Form = ({ type }) => {
  const {
    handleSubmit,
    register,
    errors,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = data => {
    setLoading(true)

    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${window.location.origin}/resources/php/calcular-precio-form.php`, true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

    // request state change event
    xhr.onreadystatechange = function () {
      try {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'calcularPrecioForm',
          eventAction: 'submit',
        })
      } catch (error) {
        console.error(error)
      }

      // request completed?
      if (xhr.readyState !== 4) return

      if (xhr.status === 200) {
        setSuccess(true)
        setLoading(false)

        // request successful - show response
        console.log(xhr.responseText)

        resetForm()
      } else {
        setError(true)
        setLoading(false)

        // request error
        console.log('HTTP error', xhr.status, xhr.statusText)
      }
    }

    // start request
    xhr.send(JSON.stringify({ ...data, tipo: 'caravana estatica' }))
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="calcular-precio-form"
    >
      <h3 className="font-weight-bold">Datos de la caravana</h3>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="marca">Marca <span className="text-muted">(opcional)</span></label>

            <input
              type="text"
              min="0"
              className={`form-control ${errors.marca && 'is-invalid'}`}
              id="marca"
              name="marca"
              ref={register({ required: false })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="modelo">Modelo <span className="text-muted">(opcional)</span></label>

            <input
              type="text"
              min="0"
              className={`form-control ${errors.modelo && 'is-invalid'}`}
              id="modelo"
              name="modelo"
              ref={register({ required: false })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="anoFabricacion">Año de fabricación</label>

            <input
              type="number"
              min="0"
              className={`form-control ${errors.anoFabricacion && 'is-invalid'}`}
              id="anoFabricacion"
              name="anoFabricacion"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="codigoPostal">Código postal</label>

            <input
              type="text"
              min="0"
              className={`form-control ${errors.codigoPostal && 'is-invalid'}`}
              id="codigoPostal"
              name="codigoPostal"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              (del camping/parking habitual/principal donde está instalada)
            </small>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="continente">
              Valor caravana a asegurar <span className="text-muted"> (a valor de nuevo)</span>
            </label>
            <select
              className={`form-control ${errors.continente && 'is-invalid'}`}
              id="continente"
              name="continente"
              ref={register({ required: true })}
            >
              <option value="">Seleccionar opción</option>
              {range(12000, 41000, 2000).map(valor => (
                <option key={valor} value={valor}>{valor}€</option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="continente2">
              Valor avance, lonas exteriores y/o tienda de cocina
            </label>
            <select
              className={`form-control ${errors.continente2 && 'is-invalid'}`}
              id="continente2"
              name="continente2"
              ref={register({ required: true })}
            >
              <option value="">Seleccionar opción</option>
              <option>No asegurar</option>
              <option value="1000">1000€</option>
              <option value="1500">1500€</option>
            </select>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="contenido">
              Valor contenido a asegurar <span className="text-muted"> (aconsejable mínimo 2.500€)</span>
            </label>
            <select
              className={`form-control ${errors.contenido && 'is-invalid'}`}
              id="contenido"
              name="contenido"
              ref={register({ required: true })}
            >
              <option value="">Seleccionar opción</option>
              <option>No asegurar</option>
              <option value="1500">1500€</option>
              <option value="2500">2500€</option>
              <option value="3500">3500€</option>
              <option value="5000">5000€</option>
            </select>
            <small className="form-text text-muted">
              Mobiliario y enseres (objetos interior caravana: ropa, TV, ajuar, menaje...)
            </small>
          </div>
        </div>
        <div className="col-sm">
        </div>
      </div>

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.coberturaDanosAvanceMeteo && 'is-invalid'}`}
          id="coberturaDanosAvanceMeteo"
          name="coberturaDanosAvanceMeteo"
          ref={register()}
        />
        <label className="form-check-label" htmlFor="coberturaDanosAvanceMeteo">
          Cobertura de daños en avances/tiendas de cocina por meteorología
        </label>
      </div>

      <hr className="m-5" />

      <h3 className="font-weight-bold">Datos del tomador</h3>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="persona">Persona</label>
            <select
              className={`form-control ${errors.persona && 'is-invalid'}`}
              id="persona"
              name="persona"
              ref={register({ required: true })}
            >
              <option>Física</option>
              <option>Jurídica</option>
            </select>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="numDocumento">NIF (DNI, CIF, NIE)</label>
            <input
              type="text"
              className={`form-control ${errors.numDocumento && 'is-invalid'}`}
              id="numDocumento"
              name="numDocumento"
              ref={register({ required: true })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="nombre">Nombre y apellidos</label>
            <input
              className={`form-control ${errors.nombre && 'is-invalid'}`}
              type="text"
              id="nombre"
              name="nombre"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="fechaNacimiento">Fecha de nacimiento</label>
            <input
              className={`form-control ${errors.fechaNacimiento && 'is-invalid'}`}
              type="text"
              id="fechaNacimiento"
              name="fechaNacimiento"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">Ejemplo: 01/02/1975</small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={`form-control ${errors.email && 'is-invalid'}`}
              type="text"
              id="email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'El formato del email es invalido',
                },
              })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="telefono">Num. teléfono <span className="text-muted">(opcional)</span></label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.telefono && 'is-invalid'}`}
              id="telefono"
              name="telefono"
              ref={register({ required: false })}
            />
          </div>
        </div>
      </div>

      <hr className="m-5" />

      <div className="form-group">
        <label htmlFor="comentarios">
          Comentarios
          <span className="text-muted"> (opcional)</span>
        </label>
        <textarea
          className="form-control"
          id="comentarios"
          name="comentarios"
          rows="3"
          ref={register()}
        />
      </div>

      <hr className="m-5" />

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.acepto && 'is-invalid'}`}
          id="acepto"
          name="acepto"
          ref={register({ required: true })}
        />
        <label className="form-check-label" htmlFor="acepto">
          He leído y acepto la <Link to='/nota-legal' target="_blank">política de privacidad</Link>
        </label>
        {errors.acepto &&
          <div>
            <small className="text-danger">Debe aceptar la política de privacidad para poder contatar con nosotros</small>
          </div>
        }
      </div>

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.whatsapp && 'is-invalid'}`}
          id="whatsapp"
          name="whatsapp"
          ref={register()}
        />
        <label className="form-check-label" htmlFor="whatsapp">
          Quiero recibir la propuesta por <FontAwesomeIcon icon={faWhatsapp} className="" /> Whatsapp
        </label>
      </div>

      <br />

      {error &&
        <div className="alert alert-danger" role="alert">
          Error. El formulario no pudo ser enviado.
        </div>
      }

      {success &&
        <div className="alert alert-success" role="alert">
          Formulario enviado. En breve recibira nuestra propuesta por email.
        </div>
      }

      <button
        type="submit"
        className={`btn btn-lg btn-primary ${loading && 'disabled'}`}
      >
        {loading &&
          <>
            <span className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </span>
            {' '}
          </>
        }
        CALCULAR PRECIO
      </button>
    </form>
  )
}

export default Form
