export function handleOnClickVerCoberturas () {
  window.scrollTo(0, 100)
  setTimeout(() => {
    document.getElementById('coberturas-tab-link').click()
  }, 500)
}

export function handleOnClickContratar () {
  document.getElementById('form-content').scrollIntoView()
}

export function scrollToFormIfFormUrlParam () {
  if (window.location.href.indexOf('?form') !== -1) {
    handleOnClickContratar()
  }
}
