import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'

import MainLinks from './../../MainLinks/container'


class ModalMenu extends Component {
  closeModalMenu = () => {
    this.props.onCloseModalMenu()
  }

  render () {
    const { shown } = this.props

    return (
      <Modal show={shown} onHide={this.closeModalMenu}>
        <Modal.Body className="modalMenu-body">
          <MainLinks />
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalMenu
