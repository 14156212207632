import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import { faBolt, faBurn, faPooStorm, faWater, faUserSecret, faUsers, faGlassMartini, faTools, faGavel } from '@fortawesome/free-solid-svg-icons'

import {
  handleOnClickVerCoberturas,
  handleOnClickContratar,
  scrollToFormIfFormUrlParam,
} from './../../common'
import Form from './Form'
import ListIcon from './../../../../ListIcon'


class CalcularPrecio extends PureComponent {
  handleOnClickVerCoberturas = handleOnClickVerCoberturas

  handleOnClickContratar = handleOnClickContratar

  componentDidMount () {
    scrollToFormIfFormUrlParam()
  }

  render () {
    return (
      <div>
        <div className="container pt-5 pb-5">

          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div
                id="card-caravana-estatica"
                className="card mb-4"
              >
                <div className="card-body">
                  <h1 className="h3">
                    Seguro para caravanas fijas en campings
                  </h1>
                  <hr className="mb-4" />

                  <div className="mt-4">
                    <Tabs
                      defaultActiveKey="info"
                    >
                      <Tab
                        eventKey="info"
                        id="info-tab-link"
                        title="Información general"
                      >
                        <div className="ml-2 mr-2 mt-3 mb-3">
                          <p>
                            Con el Seguro para caravanas no tendrá que preocuparte por los gastos ocasionados por la pérdida o daños sufridos.
                            <br /><br />
                            Podrá configurar qué coberturas serán las que protejan su vehículo y cómo pagar la cuota.
                          </p>
                        </div>
                      </Tab>
                      <Tab
                        eventKey="coberturas"
                        id="coberturas-tab-link"
                        title="Coberturas"
                      >
                        <div className="ml-2 mr-2 mt-3 mb-3">
                          <div className="row">
                            <div className="col">
                              <h6 className="strong mt-4" >- Continente</h6>
                              <p className="small ml-2">
                                La caravana, el avance, lonas exteriores, tienda de cocina.
                                <br />
                                Los anexos también son asegurables (vayas y anexos).
                              </p>

                              <h6 className="strong mt-4" >- Contenido</h6>
                              <p className="small ml-2">
                                Ajuar y objectos personales que se encuentran en el interior de la caravana y sean propiedad del asegurado.
                              </p>

                              <hr />

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faBurn} />
                                {' '}Incendio y complementarios
                              </h6>
                              <ul className="small">
                                <li>Incendio</li>
                                <li>Extinción</li>
                                <li>Explosión</li>
                                <li>Rayo</li>
                                <li>Efectos secundarios</li>
                              </ul>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faBolt} />
                                {' '}Daños eléctricos
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faPooStorm} />
                                {' '}Fenómenos atmosféricos y otros daños materiales
                              </h6>
                              <ul className="small">
                                <li>Actos de vandalismo o malintencionados</li>
                                <li>Lluvia, viento (excepto tornados), pedrisco, nieve</li>
                                <li>Inundación por rotura, desboardamiento o desviación accidental</li>
                                <li>Humo producido por fugas o escapes repentinos</li>
                                <li>Choque o impacto de vehículos terrestres</li>
                                <li>Caída de astronaves, aeronaves u objetos que caigan de los bienes asegurados</li>
                                <li>Ondas sónicas producidas por astronaves o aeronaves</li>
                              </ul>
                            </div>
                            <div className="col">
                              <h6 className="strong mt-4" >
                                <ListIcon icon={faWater} />
                                {' '}Daños por agua
                              </h6>
                              <ul className="small">
                                <li>Escapes de agua utilizada en la caravana</li>
                                <li>Olvidos u omisiones en el cierre de grifos</li>
                              </ul>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faUserSecret} />
                                {' '}Robo</h6>
                              <ul className="small">
                                <li>Robo</li>
                                <li>Expoliación</li>
                                <li>Hurto</li>
                              </ul>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faUsers} />
                                {' '}Responsabilidad Civil Familiar
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faGlassMartini} />
                                {' '}Roturas de Espejos y Cristales
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faGlassMartini} />
                                {' '}Rotura de sanitarios
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faGlassMartini} />
                                {' '}Rotura de encimeras
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faGlassMartini} />
                                {' '}Rotura de placas vitrocerámicas
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faTools} />
                                {' '}Asistencia Hogar
                              </h6>

                              <h6 className="strong mt-4" >
                                <ListIcon icon={faGavel} />
                                {' '}Reclamación y defensa jurídica
                              </h6>
                            </div>
                          </div>
                        </div>
                      </Tab>
                    </Tabs>

                    <hr />
                    <div className="d-md-flex justify-content-between">
                      <div>
                        <div
                          className="btn btn-primary mr-md-2 mb-1 d-block d-md-inline-block"
                          onClick={this.handleOnClickContratar}
                        >
                          CONTRATAR
                        </div>
                        {/* <div
                          className="btn btn-outline-primary mr-md-2 mb-1 d-block d-md-inline-block">
                            SOLICITAR LLAMADA
                        </div> */}
                      </div>
                      <div
                        className="btn btn-warning mb-1 mt-3 mt-md-0 d-block d-md-inline-block"
                        onClick={this.handleOnClickVerCoberturas}
                      >Ver todas las coberturas {'>'}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row" id="form-content">
            <div className="col-md-1"></div>
            <div className="col-md-10">
              <div className="card card-calcular-precio">
                <div className="card-header">
                  Formulario presupuesto / información
                </div>
                <div className="card-body">
                  <Form />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default CalcularPrecio
