import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
// import Req from './Required'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const resetForm = () => {
  window.document.getElementById('calcular-precio-form').reset()
}

const Form = ({ type }) => {
  const {
    handleSubmit,
    register,
    errors,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = data => {
    setLoading(true)

    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${window.location.origin}/resources/php/calcular-precio-form.php`, true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

    // request state change event
    xhr.onreadystatechange = function () {
      try {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'calcularPrecioForm',
          eventAction: 'submit',
        })
      } catch (error) {
        console.error(error)
      }

      // request completed?
      if (xhr.readyState !== 4) return

      if (xhr.status === 200) {
        setSuccess(true)
        setLoading(false)

        // request successful - show response
        console.log(xhr.responseText)

        resetForm()
      } else {
        setError(true)
        setLoading(false)

        // request error
        console.log('HTTP error', xhr.status, xhr.statusText)
      }
    }

    // start request
    xhr.send(JSON.stringify({ ...data, tipo: 'caravana circulacion' }))
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="calcular-precio-form"
    >
      <h3 className="font-weight-bold">Datos de la caravana</h3>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="anoFabricacion">Año de fabricación</label>

            <input
              type="number"
              min="0"
              className={`form-control ${errors.anoFabricacion && 'is-invalid'}`}
              id="anoFabricacion"
              name="anoFabricacion"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="tara">Tara (kg)</label>

            <input
              type="number"
              min="0"
              className={`form-control ${errors.tara && 'is-invalid'}`}
              id="tara"
              name="tara"
              ref={register({ required: true })}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="ambitoTerritorial">Ámbito territorial</label>

            <select
              id="ambitoTerritorial"
              name="ambitoTerritorial"
              ref={register({ required: true })}
              className={`form-control ${errors.ambitoTerritorial && 'is-invalid'}`}
            >
              <option value="">Seleccionar opción</option>
              <option value="16">España/Andorra</option>
              <option value="17">Unión Europea limitada</option>
              <option value="1">Europa</option>
              <option value="18">Otros / a especificar</option>
            </select>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="estanciaHabitual">Lugar de estancia habitual (hibernaje)</label>

            <select
              id="estanciaHabitual"
              name="estanciaHabitual"
              className={`form-control ${errors.estanciaHabitual && 'is-invalid'}`}
              ref={register({ required: true })}
            >
              <option value="">Seleccionar opción</option>
              <option value="C">Cámping</option>
              <option value="GP">Garaje particular</option>
              <option value="GC">Garaje comunitario</option>
              <option value="E">Aparcada en exterior</option>
              <option value="CV">Caravaning</option>
              <option value="O">Otros</option>
            </select>
          </div>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="valorNuevo">Valor de la caravana (a nuevo)</label>
            <input
              type="number"
              min="0"
              className={`form-control ${errors.valorNuevo && 'is-invalid'}`}
              id="valorNuevo"
              name="valorNuevo"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              No incluir el valor de los accesorios de serie
            </small>
          </div>
        </div>
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="valorAccesorios">Valor accesorios no de serie</label>
            <input
              type="number"
              min="0"
              className={`form-control ${errors.valorAccesorios && 'is-invalid'}`}
              id="valorAccesorios"
              name="valorAccesorios"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Electrodomésticos de línea blanca y marrón, aparatos de imagen, sonido telefonía y similares. <br />
              Otros elementos relacionados con el uso lúdico de la caravana.
            </small>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm">
          <div className="form-group">
            <label htmlFor="valorMenaje">Valor menaje</label>
            <input
              type="number"
              min="0"
              className={`form-control ${errors.valorMenaje && 'is-invalid'}`}
              id="valorMenaje"
              name="valorMenaje"
              ref={register({ required: true })}
            />
            <small className="form-text text-muted">
              Conjunto de muebles, ropas y enseres de uso común que no sobrepasen los 150 euros por unidad, pareja o juego.
            </small>
          </div>
        </div>
        <div className="col-sm">
        </div>
      </div>

      <hr className="m-5" />

      <h3 className="font-weight-bold">Datos del tomador</h3>

      {/* <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="persona">Persona</label>
            <select
              className={`form-control ${errors.persona && 'is-invalid'}`}
              id="persona"
              name="persona"
              ref={register({ required: true })}
            >
              <option>Física</option>
              <option>Jurídica</option>
            </select>
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="numDocumento">Num. documento</label>
            <input
              type="text"
              className={`form-control ${errors.numDocumento && 'is-invalid'}`}
              id="numDocumento"
              name="numDocumento"
              ref={register()}
            />
          </div>
          {errors.numDocumento && errors.numDocumento.required}
        </div>
      </div> */}

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="nombre">Nombre y apellidos</label>
            <input
              className={`form-control ${errors.nombre && 'is-invalid'}`}
              type="text"
              id="nombre"
              name="nombre"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="col">
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={`form-control ${errors.email && 'is-invalid'}`}
              type="text"
              id="email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'El formato del email es invalido',
                },
              })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="telefono">Num. teléfono <span className="text-muted">(opcional)</span></label>
            <input
              type="text"
              min="0"
              className={`form-control ${errors.telefono && 'is-invalid'}`}
              id="telefono"
              name="telefono"
              ref={register({ required: false })}
            />
          </div>
          {errors.numDocumento && errors.numDocumento.required}
        </div>
      </div>

      <hr className="m-5" />

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.acepto && 'is-invalid'}`}
          id="acepto"
          name="acepto"
          ref={register({ required: true })}
        />
        <label className="form-check-label" htmlFor="acepto">
          He leído y acepto la <Link to='/nota-legal' target="_blank">política de privacidad</Link>
        </label>
        {errors.acepto &&
          <div>
            <small className="text-danger">Debe aceptar la política de privacidad para poder contatar con nosotros</small>
          </div>
        }
      </div>

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.whatsapp && 'is-invalid'}`}
          id="whatsapp"
          name="whatsapp"
          ref={register()}
        />
        <label className="form-check-label" htmlFor="whatsapp">
          Quiero recibir la propuesta por <FontAwesomeIcon icon={faWhatsapp} className="" /> Whatsapp
        </label>
      </div>

      <br />

      {error &&
        <div className="alert alert-danger" role="alert">
          Error. El formulario no pudo ser enviado.
        </div>
      }

      {success &&
        <div className="alert alert-success" role="alert">
          Formulario enviado. En breve recibira nuestra propuesta por email.
        </div>
      }

      <button
        type="submit"
        className={`btn btn-lg btn-primary ${loading && 'disabled'}`}
      >
        {loading &&
          <>
            <span className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </span>
            {' '}
          </>
        }
        CALCULAR PRECIO
      </button>
    </form>
  )
}

export default Form
