import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

const resetForm = () => {
  window.document.getElementById('contact-form').reset()
}

const Form = () => {
  const {
    handleSubmit,
    register,
    errors,
    watch,
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onSubmit = data => {
    setLoading(true)

    const xhr = new XMLHttpRequest()
    xhr.open('POST', `${window.location.origin}/resources/php/contact-form.php`, true)
    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8')

    // request state change event
    xhr.onreadystatechange = function () {
      try {
        window.ga('send', {
          hitType: 'event',
          eventCategory: 'contactoForm',
          eventAction: 'submit',
        })
      } catch (error) {
        console.error(error)
      }

      // request completed?
      if (xhr.readyState !== 4) return

      if (xhr.status === 200) {
        setSuccess(true)
        setLoading(false)

        // request successful - show response
        console.log(xhr.responseText)

        resetForm()
      } else {
        setError(true)
        setLoading(false)

        // request error
        console.log('HTTP error', xhr.status, xhr.statusText)
      }
    }

    // start request
    xhr.send(JSON.stringify(data))
  }

  const asunto = watch('asunto') || ''

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id="contact-form"
    >
      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="persona">Nombre</label>
            <input
              type="text"
              className={`form-control ${errors.nombre && 'is-invalid'}`}
              id="nombre"
              name="nombre"
              ref={register({ required: true })}
            />
          </div>
        </div>
        <div className="col">
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              className={`form-control ${errors.email && 'is-invalid'}`}
              type="text"
              id="email"
              name="email"
              ref={register({
                required: true,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
                  message: 'El formato del email es invalido',
                },
              })}
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group">
            <label htmlFor="telefono">Num. teléfono <span className="text-muted">(opcional)</span></label>
            <input
              type="text"
              className={`form-control ${errors.telefono && 'is-invalid'}`}
              id="telefono"
              name="telefono"
            />
          </div>
          {errors.numDocumento && errors.numDocumento.required}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <label htmlFor="asunto">Asunto</label>
          <textarea
            maxLength="750"
            className={`form-control ${errors.asunto && 'is-invalid'}`}
            id="asunto"
            name="asunto"
            ref={register({
              required: true,
            })}
          />
          <span className="text-muted">
            <span className={`${(asunto.length > 700 && 'text-danger') || 'text-muted'}`}>{asunto.length}</span>
            {' '}
            (max 750 carácteres)
          </span>
        </div>
      </div>

      <hr className="mt-4 mb-4" />

      <div className="form-group form-check">
        <input
          type="checkbox"
          className={`form-check-input ${errors.acepto && 'is-invalid'}`}
          id="acepto"
          name="acepto"
          ref={register({ required: true })}
        />
        <label className="form-check-label" htmlFor="acepto">
          He leído y acepto la <Link to='/nota-legal' target="_blank">política de privacidad</Link>
        </label>
        {errors.acepto &&
          <div>
            <small className="text-danger">Debe aceptar la política de privacidad para poder contatar con nosotros</small>
          </div>
        }
      </div>

      {error &&
        <div className="alert alert-danger" role="alert">
          Error. El mensaje no pudo ser enviado.
        </div>
      }

      {success &&
        <div className="alert alert-success" role="alert">
          El mensaje fue enviado con éxito. En breve nos pondremos en contacto.
        </div>
      }

      <button
        type="submit"
        className={`btn btn-lg btn-primary ${loading && 'disabled'}`}
      >
        {loading &&
          <>
            <span className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </span>
            {' '}
          </>
        }
        CONTACTAR
      </button>
    </form>
  )
}

export default Form
