/* eslint-disable react/display-name */
import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown } from '@fortawesome/free-solid-svg-icons'


const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
    className="a nav-link main-link"
  >
    {children}
    <FontAwesomeIcon
      icon={faSortDown}
      style={{
        position: 'relative',
        top: '-5px',
        right: '-5px',
        opacity: 0.75,
      }}
    />
  </a>
))

class DropDownDiv extends Component {
  render () {
    return (
      <div
        className="main-link m-2"
        onClick={this.props.onClick}
      >
        {this.props.children}
      </div>
    )
  }
}


class MainLinks extends Component {
  render () {
    const { history } = this.props

    return (
      <>
        <Dropdown
          id="basic-nav-dropdown"
          className="nav-link main-link p-0"
          bsPrefix="main-link"
        >
          <Dropdown.Toggle
            variant="success"
            className="nav-link main-link p-0 border-0"
            as={CustomToggle}
          >
            Caravanas
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="nav-link main-link"
              as={DropDownDiv}
              onClick={() => {
                history.push('/seguros/caravana/estatica')
              }}
            >
                Estáticas
            </Dropdown.Item>
            <Dropdown.Item
              className="nav-link main-link"
              as={DropDownDiv}
              onClick={() => {
                history.push('/seguros/caravana/circulacion')
              }}
            >
              En circulacion
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <NavLink className="nav-link main-link" to="/seguros/modulo">Módulos</NavLink>

        <Dropdown
          id="basic-nav-dropdown"
          className="nav-link main-link p-0"
          bsPrefix="main-link"
        >
          <Dropdown.Toggle
            variant="success"
            className="nav-link main-link p-0 border-0"
            as={CustomToggle}
          >
            Otros
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              className="nav-link main-link"
              as={DropDownDiv}
              onClick={() => {
                history.push('/seguros/casas-de-madera')
              }}
            >
              Casitas de madera
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <NavLink className="nav-link main-link" to="/contacto">Contacto</NavLink>
      </>
    )
  }
}

export default MainLinks
